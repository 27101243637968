var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offers
    ? _c(
        "div",
        { staticClass: "unit-offers-v1" },
        _vm._l(_vm.groupedOffers, function(o) {
          return _c("div", { staticClass: "offer-bl" }, [
            _c("div", { staticClass: "unit-offer" }, [
              _c("div", { staticClass: "offer-name h4" }, [
                _vm._v(_vm._s(o.name.de) + "\n            ")
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "expandButton show",
                on: {
                  click: function($event) {
                    return _vm.showCurrentOffer($event)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "offer-detail" },
                [
                  o.discountType === "PERCENT"
                    ? _c("div", { staticClass: "offer-condition" }, [
                        _c("span", [_vm._v("Sparen Sie")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatValue(o.value)) +
                            "%\n                    "
                        ),
                        parseInt(o.timeSpan) === 1 && o.type === "LASTMINUTE"
                          ? _c("span", [
                              _vm._v(
                                "\n                \t\tbei Anreise bis spätestens in einem Tag\n               "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        parseInt(o.timeSpan) > 1 && o.type === "LASTMINUTE"
                          ? _c("span", [
                              _vm._v(
                                "\n                    bei Anreise bis spätestens in " +
                                  _vm._s(o.timeSpan) +
                                  " Tagen\n                \t"
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        o.timeSpan === 1 && o.type === "EARLYBIRD"
                          ? _c("span", [
                              _vm._v(
                                "\n                \t\tbei Anreise in fühestens einem Tag\n              \t"
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        o.timeSpan > 1 && o.type === "EARLYBIRD"
                          ? _c("span", [
                              _vm._v(
                                "\n                    bei Anreise in frühestens " +
                                  _vm._s(o.timeSpan) +
                                  " Tagen\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        o.minStay > 1
                          ? _c("div", { staticClass: "offer-minstay" }, [
                              _c("span", [
                                _vm._v(
                                  "* bei einem Aufenthalt von mindestens " +
                                    _vm._s(o.minStay) +
                                    " Übernachtungen"
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  o.minStay > 1 && o.discountType === "XY"
                    ? _c("div", { staticClass: "offer-minstay-xy mb-2" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(o.minStay) +
                            " Nächte bleiben, nur " +
                            _vm._s(_vm.formatValue(o.value)) +
                            " Nächte bezahlen\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(o.periods, function(period) {
                    return _c("div", [
                      _vm.check4Period(period)
                        ? _c("div", { staticClass: "offer-condition" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.getTimePeriod(period)) +
                                "\n                    "
                            )
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "offer-banner" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.voMsg("tpl.text.offer")) +
                    "\n            "
                )
              ])
            ])
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }