<template>
    <div class="unit-offers-v1"
        v-if="offers">
        <div class="offer-bl"
            v-for="o in groupedOffers">
            <div class="unit-offer">
                <div class="offer-name h4">{{o.name.de}}
                </div>
                <button @click="showCurrentOffer($event)"
                    class="expandButton show"></button>

                <div class="offer-detail">

                    <div class="offer-condition"
                        v-if="o.discountType === 'PERCENT'">
                        <span>Sparen Sie</span> {{formatValue(o.value)}}%
                        <span v-if="parseInt(o.timeSpan) === 1 && o.type === 'LASTMINUTE'">
                    		bei Anreise bis spätestens in einem Tag
                   </span>

                        <span v-if="parseInt(o.timeSpan) > 1 && o.type === 'LASTMINUTE'">
                        bei Anreise bis spätestens in {{o.timeSpan}} Tagen
                    	</span>

                        <span v-if="o.timeSpan === 1 && o.type === 'EARLYBIRD'">
                    		bei Anreise in fühestens einem Tag
                  	</span>

                        <span v-if="o.timeSpan > 1 && o.type === 'EARLYBIRD'">
                        bei Anreise in frühestens {{o.timeSpan}} Tagen
                    </span>

                        <div class="offer-minstay"
                            v-if="o.minStay > 1">
                            <span>* bei einem Aufenthalt von mindestens {{o.minStay}} Übernachtungen</span>
                        </div>


                    </div>
                    <div class="offer-minstay-xy mb-2"
                        v-if="o.minStay > 1 && o.discountType === 'XY'">
                        {{o.minStay}} Nächte bleiben, nur {{formatValue(o.value)}} Nächte bezahlen
                    </div>

                    <div v-for="period in o.periods">
                        <div class="offer-condition"
                            v-if="check4Period(period)">
                            {{getTimePeriod(period)}}
                        </div>

                    </div>

                </div>

                <div class="offer-banner">
                    {{voMsg("tpl.text.offer")}}
                </div>

            </div>

        </div>
    </div>

</template>

<script>
    import V from 'voUtils/V.js'

    import {
    	getVoucher
    }
    from 'voUtils/VoucherStore.js';

    import DateEx from 'voUtils/DateEx.js';



    export default {

    	data: function() {
    		return {
    			voucher: getVoucher(),
    			lang: undefined,
    			groupedOffers: undefined
    		};
    	},

    	voVueComponent: 'unit-offers',
    	props: {
    		offers: Array,
    		unitoftheday: Boolean
    	},

    	computed: {

    	},

    	mounted: function() {

    		//console.log('Offers::', this.offers);

    		var offers = this.offers;
    		var validOffers = [];

    		for (var i = 0; i < offers.length; i++) {
    			var offer = offers[i];
    			if (this.checkOffer(offer)) {
    				validOffers.push(offer);
    			}

    		}


    		// Objekt zur Speicherung der zusammengefassten Angebote
    		var offers = {};

    		// Durchlaufen der JSON-Daten
    		for (var i = 0; i < validOffers.length; i++) {
    			var offer = validOffers[i];
    			var id = offer.id;

    			// Wenn das Angebot mit dieser ID noch nicht im 'offers'-Objekt existiert, erstellen Sie es
    			if (!offers[id]) {
    				offers[id] = {
    					id: id,
    					minStay: offer.minStay,
    					discountType: offer.discountType,
    					value: offer.value,
    					visible4interfaces: offer.visible4interfaces,
    					name: offer.name,
    					services: offer.services,
    					type: offer.type,
    					timeSpan: offer.timeSpan,
    					periods: [],
    				};
    			}

    			// Fügen Sie den Zeitraum zum Angebot hinzu
    			offers[id].periods.push({
    				fromdate: offer.fromdate,
    				tilldate: offer.tilldate,
    			});
    		}

    		// Konvertieren Sie das 'offers'-Objekt in ein Array von Angeboten
    		var result = Object.values(offers);

    		this.groupedOffers = result;

    		//console.log(result);

    	},


    	methods: {

    		showCurrentOffer: function(event) {
    			//console.log('target:::' + event.target);
    			$(event.target).next(".offer-detail").toggle();
    			$(event.target).toggleClass('show');

    		},

    		getTimePeriod: function(period) {
    			var str = ''
    			if (period.fromdate && period.tilldate) {
    				str += 'Gültig im Zeitraum vom ';
    				str += DateEx.formatDate(period.fromdate, undefined, VOFFICE.mlang);
    				str += ' bis zum ';
    				str += DateEx.formatDate(period.tilldate, undefined, VOFFICE.mlang);
    			} else if (!period.fromdate && period.tilldate) {
    				str += 'Gültig bis zum ';
    				str += DateEx.formatDate(period.tilldate, undefined, VOFFICE.mlang);
    			} else if (period.fromdate && !period.tilldate) {
    				str += 'Gültig ab ';
    				str += DateEx.formatDate(period.fromdate, undefined, VOFFICE.mlang);
    			}

    			return str;
    		},

    		check4Period: function(period) {
    			if (period && period.fromdate || period.tilldate) {
    				return true;
    			} else {
    				return false;
    			}
    		},

    		formatValue: function(value) {
    			var transValue = (value / 100);
    			return transValue.toLocaleString();
    		},

    		getDays: function(date) {
    			const today = DateEx.interfaceFormat(DateEx.getToday());
    			let diff = DateEx.getDays(date, today);
    			return diff;

    		},

    		getOfferText: function(o) {


    		},
    		checkOffer: function(o) {
    			var lang = VOFFICE.lang;
    			this.lang = lang;
    			if (o.voucherCode) {
    				//console.log('voucherAngebot::', o.name[lang]);
    				if (!this.checkVoucherCode(o.voucherCode)) {
    					//console.log('voucherAngebot::', o.name[lang]);
    					return false;
    				}
    			}
    			if (o.type === 'EARLYBIRD') {
    				if (!this.check4EarlyBird(o)) {
    					return false;
    				}
    			} else if (o.type === 'LASTMINUTE') {
    				if (!this.check4LastMinute(o)) {
    					return false;
    				}
    			} else if (o.type === 'UNITOFDAY') {
    				if (!this.unitoftheday) {
    					return false;
    				}
    			}
    			if (!this.check4OfferEnd(o)) {
    				return false;
    			}

    			return true
    		},
    		checkVoucherCode: function(code) {
    			if (code) {
    				if (this.voucher && code === this.voucher.code) {
    					return true;
    				} else {
    					return false;
    				}

    			}
    		},
    		check4LastMinute: function(o) {
    			var timeSpan = parseInt(o.minStay);
    			var tilldate = DateEx.interfaceFormat(o.tilldate);
    			var fromdate = DateEx.interfaceFormat(o.fromdate);
    			var minStay = parseInt(o.minStay);
    			var daysToFromdate = undefined;
    			if (fromdate) {
    				daysToFromdate = this.getDays(fromdate);
    				if (timeSpan) {
    					if (daysToFromdate > timeSpan) {
    						//console.log('Lastminute offer not bookable because offer start date is not reached');
    						return false;
    					}
    				}
    			}
    			return true;

    		},
    		check4EarlyBird: function(o) {
    			var timeSpan = parseInt(o.minStay);
    			var tilldate = DateEx.interfaceFormat(o.tilldate);
    			//var fromdate = '2023-12-01';
    			var fromdate = DateEx.interfaceFormat(o.fromdate);
    			var minStay = parseInt(o.minStay);

    			if (tilldate) {
    				var daysToTilldate = this.getDays(tilldate);
    				//console.log('Days until the start of the offer::', daysToTilldate);

    				if (timeSpan) {
    					if (daysToTilldate <= timeSpan + 1) {
    						//console.log('Earlybird offer is not bookable because the offer has already expired');
    						return false;
    					} else if (minStay) {
    						if (daysToTilldate < (timeSpan + minStay)) {
    							//console.log('Earlybird offer is not bookable because the offer has already expired, due to minimum rental period');
    							return false;
    						}
    					}
    				}
    			}

    			if (fromdate) {
    				var daysToFromdate = this.getDays(fromdate);
    				//console.log('Days until the end of the offer::', daysToFromdate);

    				if (timeSpan) {
    					if (daysToFromdate > timeSpan) {
    						// console.log('Offer not bookable because offer start date is not reached');
    						return false;
    					}
    				}
    			}
    			return true;
    		},
    		check4OfferEnd: function(o) {
    			var minStay = parseInt(o.minStay);
    			var tilldate = DateEx.interfaceFormat(o.tilldate);
    			var daysToTilldate = this.getDays(tilldate);

    			if (minStay && tilldate) {
    				//console.log('daysToTilldate', daysToTilldate);
    				if (daysToTilldate < minStay) {
    					//console.log('Offer not bookable because the minimum rental period exceeds the offer end date');
    					return false;
    				}
    			}
    			return true;
    		}
    	},


    };
</script>